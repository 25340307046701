import http from '../http';
import routes from '../env-routes';
import { coreURLConstruct } from '@/services/formatter.js';

async function getUserTenants() {
  try {
    const response = await http({
      method: 'GET',
      url: `${routes.aioCore}/user-extents`,
    });

    return response.data;
  } catch (error) {
    return error;
  }
}

async function getChildTenants() {
  const baseURL = coreURLConstruct();
  try {
    const response = await http({
      method: 'GET',
      url: `${baseURL}/tenants/child-tenants`,
    });

    return response.data;
  } catch (error) {
    return error;
  }
}

async function getTenantData() {
  try {
    const response = await http({
      method: 'GET',
      url: `${routes.tenant}/api/v1/tenant/${window.tenantUuid}`,
    });

    return response.data;
  } catch (error) {
    return error;
  }
}

export { getTenantData, getUserTenants, getChildTenants };
