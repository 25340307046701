import { shallowReactive } from 'vue';
import { v4 as uuidv4 } from 'uuid';

const notificationTimeouts = {};

export const notificationsStore = shallowReactive(new Map());

export function removeNotification(id) {
  notificationsStore.delete(id);

  clearTimeout(notificationTimeouts[id]);
  delete notificationTimeouts[id];
}

export function addNotification(notification) {
  const id = notification?.id || uuidv4();
  const timeoutValue = notification?.timeout ?? 5000;

  if (notificationTimeouts[id]) {
    clearTimeout(notificationTimeouts[id]);
    delete notificationTimeouts[id];
  }

  notificationsStore.set(id, {
    ...notification,
    id,
  });

  if (timeoutValue) {
    notificationTimeouts[id] = setTimeout(() => {
      clearTimeout(notificationTimeouts[id]);
      removeNotification(id);
    }, timeoutValue);
  }
}
