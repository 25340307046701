import http from '../http';
import { errorPayload, successPayload } from '@/services/formatter';

async function getCurrencies() {
  try {
    const response = await http({
      url: `/currencies`,
      method: 'get',
    });

    return successPayload(response);
  } catch (error) {
    return errorPayload(error);
  }
}

export { getCurrencies };
